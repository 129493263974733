<template>
  <div>
    <div class="modal fade" :id="id">
      <div v-loading="isLoading" class="modal-dialog modal-lg shadow" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
            >{{ $t(isUpdate?'Cập nhật thông tin Khách mời':'Thêm mới Khách mời') }}</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="row">
              <div class="col-md-6">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t('Họ và tên') }}</label>
                  <input
                    v-model="form.name"
                    type="text"
                    class="form--input fs-14 fw-500 h-10"
                    :placeholder="$t('Nhập họ và tên')"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t('Email') }}</label>
                  <input
                    :disabled="isUpdate"
                    v-model="form.email"
                    type="text"
                    class="form--input fs-14 fw-500 h-10"
                    :class="isUpdate&&'cursor-not-allowed cs-disabled'"
                    :placeholder="$t('Nhập email')"
                  />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t('Số điện thoại') }}</label>
                  <input
                    v-model="form.phone"
                    type="text"
                    class="form--input fs-14 fw-500 h-10"
                    :placeholder="$t('Nhập số điện thoại')"
                  />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t('Chức vụ') }}</label>
                  <input
                    v-model="form.job_title"
                    type="text"
                    class="form--input fs-14 fw-500 h-10"
                    :placeholder="$t('Nhập chức vụ')"
                  />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t('Nơi làm việc') }}</label>
                  <input
                    v-model="form.workplace"
                    type="text"
                    class="form--input fs-14 fw-500 h-10"
                    :placeholder="$t('Nhập nơi làm việc')"
                  />
                </div>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button
                :disabled="isLoading"
                @click="handleSubmit"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t(isUpdate?'Cập nhật':'Thêm mới') }}</div>
              </button>
              <button
                :disabled="isLoading"
                @click="showModalEditGuest(false)"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t('Hủy') }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { ButtonHoDo } from '@/components/Button'

export default {
  name: 'ModalEditGuest',
  components: { ButtonHoDo },
  props: {
    guestInfo: {
      type: Object,
      default: function () {
        return {
          name: '',
          email: '',
          job_title: '',
          workplace: '',
          phone: ''
        }
      }
    },
    isUpdate: Boolean,
    id: String
  },
  data () {
    const initForm = {
      name: '',
      email: '',
      job_title: '',
      workplace: '',
      phone: ''
    }
    return {
      isLoading: false,
      form: initForm,
      initForm
    }
  },
  computed: {},
  watch: {
    guestInfo: {
      deep: true,
      handler () {
        this.handleMapData()
      }
    }
  },
  created () {
    this.handleMapData()
  },
  methods: {
    showModalEditGuest (show) {
      this.form = { ...this.initForm }
      window.$(`#${this.id}`).modal(show ? 'show' : 'hide')
    },
    handleMapData () {
      this.form = {
        name: this.guestInfo.name,
        email: this.guestInfo.email,
        job_title: this.guestInfo.job_title,
        workplace: this.guestInfo.workplace,
        phone: this.guestInfo.phone
      }
    },
    async handleUpdateGuestInfo () {
      if (!this.guestInfo?.id) return

      try {
        this.isLoading = true
        const id = this.guestInfo.id

        const params = {
          job_title: this.form.job_title,
          workplace: this.form.workplace,
          phone: this.form.phone,
          name: this.form.name
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .updateConferenceMemberInfo(id, params)

        this.showModalEditGuest(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Cập nhật thông tin khách mời thành công')
        })

        console.log(response)
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi cập nhật thông tin khách mời')
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleCreateGuestInfo () {
      if (!this.$route.params.conf_id) return

      try {
        this.isLoading = true

        const data = {
          ...this.form,
          conference_id: Number(this.$route.params.conf_id),
          registration_type: 1
        }
        const res = await this.$rf
          .getRequest('ConferenceRequest')
          .createConferenceMemberInfo(data)

        if (!res.data) return

        this.showModalEditGuest(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Thêm mới khách mời thành công')
        })
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi thêm mới khách mời')
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit () {
      if (this.guestInfo?.id) {
        this.handleUpdateGuestInfo()
      } else {
        this.handleCreateGuestInfo()
      }
    }
  }
}
</script>

<style scoped>
.cs-disabled {
  background-color: #cdcdcd74;
  color: #828282;
}
</style>
